import { POST, UPLOAD } from '../utils/request';
var baseURL = process.env.VUE_APP_GIMP_API;
import Cookies from 'js-cookie';

const userToken = JSON.parse(Cookies.get('userToken') || '{}');
const userId = userToken._user;

// 上传图片
export const uploadFile = (params) =>
  UPLOAD('/service?service=sys.lookMenu&action=uploadImg', params, {
    baseURL: baseURL,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

// 功能点评接口

/**
 * @description 获取功能点评列表
 * @param {*} menuId 菜单id
 * @param {*} justMyself 是否只看自己	1是0否
 * @return {*}
 **/
export const getFunctionReviewList = (params) =>
  POST(
    '/service?service=sys.lookMenu&action=getFunctionReviewList',
    {
      userId,
      ...params,
    },
    {
      baseURL: baseURL,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );

/**
 * @description 提交点评
 * @param {*} menuId 菜单id
 * @param {*} content 评价内容
 * @param {*} image 图片
 * @param {*} star 星级
 * @return {*}
 **/
export const addReview = (params) =>
  POST(
    '/service?service=sys.lookMenu&action=addReview',
    {
      userId,
      ...params,
    },
    {
      baseURL: baseURL,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );

/**
 * @description 点赞开发者
 * @param {*} menuId 菜单id
 * @param {*} clickUserId 被点赞用户id
 * @return {*}
 **/
export const addLikeNum = (params) =>
  POST(
    '/service?service=sys.lookMenu&action=addLikeNum',
    {
      userId,
      ...params,
    },
    {
      baseURL: baseURL,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );

/**
 * @description 点赞评论
 * @param {*} id 评论id
 * @return {*}
 **/
export const addReviewNum = (params) =>
  POST(
    '/service?service=sys.lookMenu&action=addReviewNum',
    {
      userId,
      ...params,
    },
    {
      baseURL: baseURL,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );

/**
 * @description 删除评论
 * @param {*} menuId 菜单id
 * @return {*}
 **/
export const deleteReview = (params) =>
  POST(
    '/service?service=sys.lookMenu&action=deleteReview',
    {
      userId,
      ...params,
    },
    {
      baseURL: baseURL,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );

// 功能点评接口结束
