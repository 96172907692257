import axios from 'axios';
import Vue from 'vue';
import { Message } from 'element-ui';
const LOGIN_URL = process.env.VUE_APP_LOGIN_URL;
import Cookies from 'js-cookie';

let baseURL = process.env.VUE_APP_BASE_API;
const service = axios.create({
  baseURL: baseURL, //
  withCredentials: false, // send cookies when cross-domain requests
  timeout: 20000,
  // headers: {
  //   'content-type': 'application/json',
  // },
});

// 请求拦截器
service.interceptors.request.use(
  (config) => {
    const userToken = JSON.parse(Cookies.get('userToken') || '{}');

    if (!config.params) {
      config.params = {};
    }

    config.params = Object.assign({}, config.params, {
      _token: userToken._token,
      _user: userToken._user,
    });
    let time_stamp = Date.parse(new Date()).toString();
    config.headers['timestamp'] = time_stamp;
    config.headers['token'] = userToken._token;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);
function f_login() {
  const _url = window.location.origin;
  const href = LOGIN_URL + '&_url=' + encodeURIComponent(_url);
  window.location.href = href;
}
// 添加响应拦截器
service.interceptors.response.use(
  function (response) {
    Vue.prototype.$hideLoading();
    if (response.config.url.includes('/adreport')) return response;
    // 对响应数据做点什么
    const res = response.data;
    if (res.code == 401) {
      // TODO
      Cookies.remove('userToken');
      Cookies.remove('userToken', {
        domain: '.giikin.com',
      });
      f_login();
    } else if (res.code != 0 && res.code != 401 && res.code != 200) {
      if (res.code == 105000) {
        Message({
          message: '已收藏，请不要重复添加！',
          type: 'warning',
          duration: 5 * 1000,
        });
      } else {
        Message({
          message: res.comment,
          type: 'error',
          duration: 5 * 1000,
        });
      }
    }
    return response;
  },
  function (err) {
    Vue.prototype.$hideLoading();
    if (err.toString().includes('401') && !err.message.includes('timeout')) {
      Cookies.remove('userToken');
      Cookies.remove('userToken', {
        domain: '.giikin.com',
      });
      f_login();
    } else {
      if (err.config.url.includes('/addUserLookMenu')) return;
      // 对响应错误做点什么
      Message({
        message: err.message.includes('timeout') ? '请求超时' : err.message,
        type: 'error',
        duration: 5 * 1000,
      });
    }
  },
);

const GET = (url, params, config) => {
  return service({
    url,
    method: 'GET',
    params,
    ...config,
  }).then((res) => {
    // Vue.prototype.$hideLoading();
    return res?.data;
  });
};

const POST = (url, data, config) => {
  const userToken = JSON.parse(Cookies.get('userToken') || '{}');
  if (data) {
    data = {
      ...data,
      userId: userToken._user,
    };
  }
  return service({
    url,
    method: 'POST',
    data: JSON.stringify(data),
    ...config,
  }).then((res) => {
    return res?.data;
  });
};

const UPLOAD = (url, data, config) => {
  return service({
    url,
    method: 'POST',
    data: data,
    ...config,
  }).then((res) => {
    return res?.data;
  });
};
export { POST, GET, UPLOAD };
export default service;
