<template>
  <div id="functionReview">
    <div
      ref="functionReviewBtnRef"
      class="function-review-btn"
      :style="fixed"
    >
      <div
        class="btn-mark"
        @mousedown="onMouseDown"
      >
        <i class="el-icon-edit" />
        点评
      </div>
    </div>
    <div
      id="functionReviewMask"
      class="function-mask"
    ></div>
    <el-drawer
      class="function-review-drawer"
      title="功能预览"
      :visible.sync="drawer"
      direction="rtl"
      size="850px"
      destroy-on-close
      append-to-body
      :close-on-press-escape="false"
      :wrapperClosable="false"
      :withHeader="false"
    >
      <div class="function-review-content flex">
        <div class="function-review-list">
          <div class="function-review-title flex justify-between items-center">
            <b>功能点评 {{ count }}</b>
            <el-checkbox
              v-model="checked"
              @change="getFunctionReviewInfo"
            >
              只查看我的
            </el-checkbox>
          </div>
          <div class="function-review-list-container">
            <div
              class="function-review-list-item"
              v-for="item in commentList"
              :key="item.id"
            >
              <div class="function-review-list-item-title flex justify-between items-end">
                <div class="flex">
                  <el-image
                    :src="item.avatar"
                    fit="cover"
                  />
                  <div class="flex direction-column justify-between">
                    <h3>{{ item.username }}</h3>
                    <p>{{ item.org_name }} <b>·</b> {{ item.job_name }}</p>
                  </div>
                </div>
                <el-rate
                  v-model="item.star"
                  disabled
                  text-color="#ff9900"
                />
              </div>
              <div class="function-review-list-item-content">{{ item.content }}</div>
              <div
                class="function-review-list-item-image flex items-center"
                v-if="item.image_list.length"
              >
                <el-image
                  v-for="image in item.image_list"
                  :key="image"
                  :src="image"
                  :preview-src-list="item.image_list"
                />
              </div>
              <div class="function-review-list-item-plus flex">
                <div
                  class="plus-icon"
                  @click="onSubmitReviewNum(item.id)"
                >
                  +1
                </div>
                <div class="plus-right flex">
                  <div
                    class="plus-user flex"
                    v-if="item.like_user_num"
                  >
                    <div class="plus-user-name">
                      {{ item.like_user }}
                    </div>
                  </div>
                  <div
                    class="plus-user-number"
                    v-if="item.like_user_num > 8"
                  >
                    {{ item.like_user_num }}
                  </div>
                </div>
              </div>
              <div class="function-review-list-item-time flex items-center justify-between">
                <div>
                  <i class="el-icon-time" />
                  {{ item.intime }}
                </div>
                <el-button
                  v-if="userId === item.user_id"
                  type="text"
                  size="small"
                  icon="el-icon-delete"
                  @click="onDeleteReview(item.id)"
                />
              </div>
            </div>
            <div class="function-review-no-more">没有更多了</div>
          </div>
        </div>
        <div class="function-review-info">
          <div class="flex justify-flex-end">
            <div
              class="function-review-info-close"
              @click="drawer = false"
            >
              <i class="el-icon-close"></i>
            </div>
          </div>
          <div class="function-review-info-sys">{{ systemName }}</div>
          <div class="function-review-info-title">{{ moduleName }} / {{ menuName }}</div>
          <div class="function-review-info-rate flex items-center">
            整体满意度：
            <template v-if="starAvg">
              <el-rate
                v-model="starAvg"
                disabled
                text-color="#ff9900"
                show-score
                score-template="{value}分"
              />
            </template>
            <template v-else> 暂无评分 </template>
          </div>
          <el-button
            type="primary"
            size="default"
            @click="dialog = true"
          >
            功能点评
          </el-button>
          <div class="function-review-info-user">项目参与人（{{ developers.length }}）</div>
          <div class="function-review-info-user-list">
            <div
              class="function-review-info-user-item"
              v-for="(developer, index) in developers"
              :key="index"
            >
              <div class="dingtalk-wrap">
                <el-image
                  :src="developer.avatar"
                  fit="cover"
                />
                <div
                  class="dingtalk-btn flex items-center justify-center"
                  @click="onDingTalk(developer.dd_account)"
                >
                  <el-image :src="dingTalk" />
                </div>
              </div>
              <p>{{ developer.username }}</p>
              <div class="job">{{ developer.job_name }}</div>
              <div
                class="like-svg flex items-center justify-center"
                :class="{ 'like-svg-active': developer.is_like }"
              >
                <div
                  class="lik-svg-icon"
                  @click="onSubmitLike(developer.user_id)"
                >
                  <svg
                    t="1713410764481"
                    class="icon"
                    viewBox="0 0 1024 1024"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    p-id="1642"
                    width="200"
                    height="200"
                  >
                    <path
                      d="M710.549333 384.810667a12409.045333 12409.045333 0 0 0 47.466667-0.32l8.746667-0.085334c83.989333-0.618667 141.44 67.584 126.72 150.229334L847.296 794.026667c-10.026667 56.448-63.914667 101.546667-121.130667 101.589333L298.624 896a42.730667 42.730667 0 0 1-42.666667-42.410667l-0.810666-383.978666a42.666667 42.666667 0 0 1 42.026666-42.666667l3.157334-0.064c5.226667-0.042667 11.797333-0.042667 19.626666 0 91.946667 0.768 170.88-86.698667 170.709334-170.944-0.149333-86.741333 39.786667-126.762667 106.453333-127.573333 62.250667-0.746667 106.602667 59.605333 107.349333 149.12 0.213333 26.602667-6.293333 73.237333-14.506666 107.434666 6.186667 0 13.077333-0.042667 20.586666-0.085333z m-497.706666 63.232L213.333333 874.624A21.312 21.312 0 0 1 191.786667 896H149.525333A21.333333 21.333333 0 0 1 128 874.624l0.042667-426.581333A21.269333 21.269333 0 0 1 149.44 426.666667h41.984c11.669333 0 21.418667 9.578667 21.418667 21.376z"
                      fill="#3D3D3D"
                      p-id="1643"
                    ></path>
                  </svg>
                </div>
                <p>{{ developer.like_num }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-drawer>

    <el-dialog
      :title="dialogTitle"
      width="500px"
      class="dialog-container"
      custom-class="dialog-wrap"
      :visible.sync="dialog"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      append-to-body
      @closed="closedDialog"
    >
      <div class="function-review-dialog">
        <el-rate
          v-model="star"
          void-icon-class="el-icon-star-on"
          allow-half
          show-score
          score-template="{value}分"
        />
        <el-input
          v-model="textarea"
          placeholder="给我们建议或反馈一些问题，粘贴上传图片~"
          type="textarea"
          :rows="6"
        />
        <div class="flex items-center image-pic">
          <el-upload
            action="#"
            :httpRequest="httpRequest"
            accept=".jpg,.jpeg,.png"
            :show-file-list="false"
          >
            <div class="upload-dialog flex items-center justify-center direction-column">
              <i class="el-icon-plus" />
              添加图片
            </div>
          </el-upload>
          <div
            class="image-item"
            v-for="(image, index) in imageList"
            :key="image"
          >
            <el-image
              :src="image"
              fit="cover"
            />
            <div class="image-btn flex items-center justify-center">
              <el-button
                type="text"
                size="default"
                @click="imageDetelt(index)"
                icon="el-icon-delete"
              />
            </div>
          </div>
        </div>
        <div
          class="function-on-submit"
          @click="onSubmitReview"
        >
          提交
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { uploadFile, getFunctionReviewList, addLikeNum, addReview, addReviewNum, deleteReview } from '../api/gimp';
import Cookies from 'js-cookie';
export default {
  name: 'functionReview',
  props: {
    menuId: {
      type: [String, Number],
      require: true,
      default: 0,
    },
  },
  data() {
    return {
      drawer: false,
      checked: false,
      value: 4.3,
      likeSvg: require('../assets/images/like.svg'),
      dingTalk: require('../assets/images/dingtalk.webp'),
      dialog: false,
      textarea: '',
      fixed: {
        right: '20px',
        bottom: '20px',
      },
      star: 0,
      imageList: [],
      developers: [],
      starAvg: 0,
      count: 0,
      commentList: [],
      systemName: '',
      moduleName: '',
      menuName: '',
    };
  },
  computed: {
    dialogTitle() {
      return `【${this.menuName}】整体满意度点评`;
    },
    userId() {
      const userToken = Cookies.get('userToken');
      return Number(JSON.parse(userToken)._user);
    },
  },
  created() {
    this.getFixed();
  },
  mounted() {
    document.addEventListener('paste', this.copyFile);
  },
  methods: {
    getFixed() {
      const fixed = JSON.parse(localStorage.getItem('funtion-review-fixed') ?? '{}');
      if (fixed.right && fixed.bottom) {
        this.fixed = fixed;
      }
    },
    // 获取菜单信息
    getFunctionReviewInfo() {
      if (this.menuId === 0) {
        this.$message.warning('无法获取到菜单ID！');
        return;
      }
      this.$showLoading({ title: '加载中...' });
      getFunctionReviewList({ menuId: this.menuId, justMyself: this.checked ? 1 : 0 }).then((res) => {
        this.$hideLoading();
        if (res.code === 0) {
          this.drawer = true;
          const { developers, starAvg, count, data, systemName, moduleName, menuName } = res.data;
          this.developers = developers;
          this.starAvg = starAvg;
          this.count = count;
          this.commentList = data.map((v) => {
            v.image_list = v.image ? v.image.split(',') : [];
            return v;
          });
          this.systemName = systemName;
          this.moduleName = moduleName;
          this.menuName = menuName;
        }
      });
    },
    // 给开放人员点赞
    onSubmitLike(id) {
      this.$showLoading();
      addLikeNum({ menuId: this.menuId, clickUserId: id }).then((res) => {
        this.$hideLoading();
        if (res.code === 0) {
          this.$message.success('操作成功');
          this.getFunctionReviewInfo();
        }
      });
    },
    // 提交评论
    onSubmitReview() {
      if (!this.star) {
        this.$message.warning('请选择评价分数！');
        return;
      }
      this.$showLoading();
      addReview({
        menuId: this.menuId,
        content: this.textarea,
        image: this.imageList.join(','),
        star: this.star,
      }).then((res) => {
        this.$hideLoading();
        if (res.code === 0) {
          this.$message.success('提交成功');
          this.dialog = false;
          this.getFunctionReviewInfo();
        }
      });
    },
    // 评论 +-1
    onSubmitReviewNum(id) {
      this.$showLoading();
      addReviewNum({ id }).then((res) => {
        this.$hideLoading();
        if (res.code === 0) {
          this.$message.success('提交成功');
          this.getFunctionReviewInfo();
        }
      });
    },
    onDeleteReview(id) {
      this.$confirm('确定删除该评论？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$showLoading();
          deleteReview({ id }).then((res) => {
            this.$hideLoading();
            if (res.code === 0) {
              this.$message.success('删除成功');
              this.getFunctionReviewInfo();
            }
          });
        })
        .catch(() => {
          console.log('取消');
        });
    },
    // 去钉钉反馈
    onDingTalk(ding) {
      if (!ding) {
        this.$message.warning('暂时无法获取该研发人员钉钉账号，请复制姓名后到钉钉搜索！');
        return;
      }
      window.open(`dingtalk://dingtalkclient/action/sendmsg?dingtalk_id=${ding}`);
    },
    // 拖动按钮
    onMouseDown(e) {
      const width = document.documentElement.clientWidth;
      const height = document.documentElement.clientHeight;
      const T = new Date().getTime();
      const { offsetX, offsetY, target } = e;
      target.style.cursor = 'grabbing';
      document.body.style.userSelect = 'none';
      // eslint-disable-next-line no-unused-vars
      let moveCursor = false;

      const mask = document.getElementById('functionReviewMask');
      mask.style.display = 'block';

      document.onmousemove = (event) => {
        moveCursor = true;
        const { clientX, clientY } = event;
        let right = width - clientX - 25;
        right = right >= 20 ? right : 20;
        right = clientX - offsetX >= 20 ? right : width - 75;

        let bottom = height - clientY - 25;
        bottom = bottom >= 20 ? bottom : 20;
        bottom = clientY - offsetY >= 20 ? bottom : height - 75;
        this.fixed = {
          right: right + 'px',
          bottom: bottom + 'px',
        };
      };

      document.onmouseup = () => {
        document.onmousemove = null;
        document.onmouseup = null;
        target.style.cursor = 'pointer';
        document.body.style.userSelect = 'text';
        mask.style.display = 'none';

        const t = new Date().getTime();
        if (t - T < 300) {
          this.getFunctionReviewInfo();
          return;
        }

        localStorage.setItem('funtion-review-fixed', JSON.stringify(this.fixed));
      };
    },
    uploadFileApi(formData) {
      console.log(formData);
      this.$showLoading({ title: '正在上传...' });
      uploadFile(formData).then((res) => {
        this.$hideLoading();
        if (res.code === 0) {
          this.imageList.push(res.data.src);
        }
      });
    },
    httpRequest(params) {
      let _file = params.file;
      let formData = new FormData();
      formData.append('file', _file);
      this.uploadFileApi(formData);
    },
    // 粘贴上传图片
    copyFile(event) {
      if (this.dialog) {
        let items = event.clipboardData && event.clipboardData.items;
        for (let i = 0; i < items.length; i++) {
          let _file = items[i].getAsFile();
          if (_file) {
            let formData = new FormData();
            formData.append('file', _file);
            this.uploadFileApi(formData);
          }
        }
      }
    },
    imageDetelt(index) {
      this.$confirm('确定删除该图片？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.imageList.splice(index, 1);
        })
        .catch(() => {
          console.log('取消');
        });
    },
    closedDialog() {
      this.imageList = [];
      this.star = 0;
      this.textarea = '';
    },
  },
};
</script>

<style lang="scss">
.function-review-drawer {
  .el-drawer__body {
    padding: 0;
  }
}
</style>

<style scoped lang="scss">
.flex {
  display: flex;
}
.justify-between {
  justify-content: space-between;
}
.justify-center {
  justify-content: center;
}
.justify-flex-end {
  justify-content: flex-end;
}
.items-center {
  align-items: center;
}
.direction-column {
  flex-direction: column;
}
.items-end {
  align-items: flex-end;
}
#functionReview {
  .function-review {
    &-btn {
      position: fixed;
      z-index: 999;
      background-color: rgba(0, 0, 255, 1);
      color: #fff;
      font-size: 12px;
      height: 50px;
      width: 50px;
      border-radius: 50px;
      cursor: pointer;
      outline: none;
      text-align: center;
      font-weight: bold;
      box-shadow: 10px 10px 10px rgba(0, 0, 255, 0.2);
      z-index: 999;

      p {
        margin-top: 2px;
      }
      .btn-mark {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 50px;
        width: 50px;
        &::after {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 1);
          // opacity: 0;
          border-radius: 50px;
          z-index: 1;
        }
      }
    }
  }
}
.function-review {
  &-title {
    padding: 20px 30px;
    b {
      font-size: 16px;
    }
  }
  &-no-more {
    color: #999;
    text-align: center;
    padding: 20px 0;
    font-size: 12px;
  }
  &-list {
    flex: 1;
    h3 {
      line-height: 18px !important;
    }
    &-container {
      height: calc(100vh - 61px);
      padding: 0 30px;
      overflow-y: auto;
      &::-webkit-scrollbar {
        display: none;
      }
    }
    &-item {
      line-height: 18px !important;

      & + & {
        margin-top: 20px;
        padding-top: 20px;
        border-top: 1px solid #ddd;
      }

      &-title {
        .el-image {
          width: 40px;
          height: 40px;
          border-radius: 40px;
          margin-right: 10px;
        }
      }
      p {
        color: #999;
        font-size: 12px;
        line-height: 14px;
      }
      &-content {
        padding: 20px 0 15px;
        line-height: 20px;
        color: #333;
        white-space: break-spaces;
      }
      &-image {
        gap: 10px;
        margin-bottom: 20px;
        .el-image {
          height: 72px;
          display: block;
          border-radius: 3px;
        }
      }
      &-plus {
        margin-bottom: 20px;
        gap: 4px;
      }
      &-time {
        color: #999;
        font-size: 12px;
      }
      .plus-icon {
        background-color: rgba(0, 51, 255, 1);
        color: #fff;
        height: 20px;
        width: 20px;
        line-height: 20px;
        text-align: center;
        border-radius: 4px;
        font-weight: bold;
        cursor: pointer;
      }
      .plus-right {
        line-height: 20px;
        height: 20px;
        border-radius: 4px;
        font-size: 12px;
        flex: 1;
        color: #666;
        border-radius: 3px;
        overflow: hidden;
      }
      .plus-user {
        flex: 1;
        width: 0;
      }
      .plus-user-name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        background-color: #eee;
        padding: 0 10px;
        height: 20px;
        border-radius: 3px;
      }
      .plus-user-number {
        background-color: #eee;
        padding-right: 10px;
      }
    }
  }
  &-info {
    width: 310px;
    border-left: 1px solid #ddd;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
    padding: 0 20px;
    color: #333;
    &-close {
      margin: 10px 0 5px;
      font-size: 20px;
      cursor: pointer;
      i {
        font-weight: bold;
      }
    }
    &-sys {
      color: #666;
    }
    &-title {
      margin: 20px 0;
      font-size: 20px;
      font-weight: bold;
    }
    &-rate {
      color: #666;
    }
    .el-button {
      background: rgb(0, 51, 255);
      border-color: rgb(0, 51, 255);
      padding: 8px 15px;
      margin: 20px 0;
    }
    &-user {
      border-top: 1px solid #ddd;
      padding: 20px 0;
      font-size: 12px;
      color: #666;
      &-list {
        display: grid;
        grid-template-columns: 30% 30% 30%;
        grid-gap: 13px;
      }
      &-item {
        text-align: center;
        .el-image {
          width: 42px;
          height: 42px;
          border-radius: 42px;
        }
        p {
          margin: 10px 0;
        }
        .job {
          background: rgba(255, 102, 51, 1);
          border-radius: 20px;
          font-size: 12px;
          color: #fff;
          padding: 2px 0;
          letter-spacing: 1px;
        }
        .lik-svg-icon {
          cursor: pointer;
        }
        .like-svg {
          gap: 5px;
          height: 18px;
          margin-top: 10px;
          svg {
            width: 18px;
            height: 18px;
          }
          &.like-svg-active {
            path {
              fill: #f00;
            }
          }
        }
      }
    }
  }
}
.dialog-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.image-pic {
  margin: 20px 0;
  gap: 10px;
}
.dingtalk-wrap {
  width: 42px;
  height: 42px;
  border-radius: 42px;
  overflow: hidden;
  position: relative;
  margin: 0 auto;
  cursor: pointer;
  .dingtalk-btn {
    background-color: rgba(0, 0, 0, 0.4);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s;
    .el-image {
      width: 34px;
      height: 34px;
    }
  }
  &:hover .dingtalk-btn {
    visibility: visible;
    opacity: 1;
  }
}
.upload-dialog,
.image-item {
  width: 80px;
  height: 80px;
  background: rgba(242, 242, 242, 1);
  border-radius: 4px;
  font-size: 12px;
  position: relative;
  overflow: hidden;
  i {
    font-size: 17px;
    font-weight: bold;
    margin-bottom: 5px;
  }
  .el-image {
    height: 80px;
  }
  .image-btn {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
    color: #fff;
    width: 100%;
    text-align: center;
    cursor: pointer;
    .el-button {
      color: #fff;
    }
  }
}
.function-on-submit {
  background-color: rgba(0, 0, 255, 1);
  color: #fff;
  text-align: center;
  line-height: 45px;
  border-radius: 4px;
  cursor: pointer;
}
.function-mask {
  position: fixed;
  z-index: 9;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: none;
}
</style>
<style lang="scss">
.function-review-content {
  .el-checkbox__label {
    font-size: 12px;
  }
  .el-rate__icon {
    margin: 0;
  }
}
.function-review-dialog {
  .el-rate {
    height: auto;
  }
  .el-rate__icon {
    font-size: 34px;
  }
  .el-rate__text {
    font-size: 22px;
    margin-left: 10px;
  }
  .el-textarea {
    margin-top: 20px;
    .el-textarea__inner {
      border: none;
      background-color: rgba(245, 245, 245, 1);
      padding: 15px;
    }
  }
}
.dialog-wrap {
  margin: 0 !important;
  border-radius: 10px;
  .el-dialog__close {
    font-weight: bold;
    font-size: 20px;
  }
}
</style>
